/* src/App.css */
.App {
  font-family: Arial, sans-serif;
  padding: 20px;
}

h1 {
  color: #333;
}

label {
  margin-right: 10px;
}

input[type="text"] {
  margin-right: 10px;
}

textarea {
  width: 100%;
  margin-top: 20px;
}

.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  /* width: 20%; */
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

/* Add some padding inside the card container */
.container {
  padding: 2px 16px;
}