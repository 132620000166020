/* General styles for the table headers */
th {
  padding: 10px;
  text-align: center;
  color: white; /* For better contrast */
}

/* Trees section */
.trees {
  background-color: green;
}

.trees-opacity-20 {
  background-color: rgba(0, 128, 0, 0.2); /* Green with 20% opacity */
}

.trees-opacity-40 {
  background-color: rgba(0, 128, 0, 0.4); /* Green with 40% opacity */
}

.trees-opacity-60 {
  background-color: rgba(0, 128, 0, 0.6); /* Green with 60% opacity */
}

.trees-opacity-80 {
  background-color: rgba(0, 128, 0, 0.8); /* Green with 80% opacity */
}

/* Soils section */
.soils {
  background-color: #8B4513; /* SaddleBrown color */
}

.soils-opacity-20 {
  background-color: rgba(139, 69, 19, 0.2); /* SaddleBrown with 20% opacity */
}

.soils-opacity-40 {
  background-color: rgba(139, 69, 19, 0.4); /* SaddleBrown with 40% opacity */
}

.soils-opacity-60 {
  background-color: rgba(139, 69, 19, 0.6); /* SaddleBrown with 60% opacity */
}

.soils-opacity-80 {
  background-color: rgba(139, 69, 19, 0.8); /* SaddleBrown with 80% opacity */
}

/* Mines section */
.mines {
  background-color: #A52A2A; /* Brown color */
}

.mines-opacity-20 {
  background-color: rgba(165, 42, 42, 0.2); /* Brown with 20% opacity */
}

.mines-opacity-40 {
  background-color: rgba(165, 42, 42, 0.4); /* Brown with 40% opacity */
}

.mines-opacity-60 {
  background-color: rgba(165, 42, 42, 0.6); /* Brown with 60% opacity */
}

.mines-opacity-80 {
  background-color: rgba(165, 42, 42, 0.8); /* Brown with 80% opacity */
}

/* Woodworking section */
.woodworking {
  background-color: #8B4513; /* SaddleBrown color */
}

.woodworking-opacity-20 {
  background-color: rgba(139, 69, 19, 0.2); /* SaddleBrown with 20% opacity */
}

.woodworking-opacity-40 {
  background-color: rgba(139, 69, 19, 0.4); /* SaddleBrown with 40% opacity */
}

.woodworking-opacity-60 {
  background-color: rgba(139, 69, 19, 0.6); /* SaddleBrown with 60% opacity */
}

.woodworking-opacity-80 {
  background-color: rgba(139, 69, 19, 0.8); /* SaddleBrown with 80% opacity */
}

/* Cooking section */
.cooking {
  background-color: #FF6347; /* Tomato color */
}

.cooking-opacity-20 {
  background-color: rgba(255, 99, 71, 0.2); /* Tomato with 20% opacity */
}

.cooking-opacity-40 {
  background-color: rgba(255, 99, 71, 0.4); /* Tomato with 40% opacity */
}

.cooking-opacity-60 {
  background-color: rgba(255, 99, 71, 0.6); /* Tomato with 60% opacity */
}

.cooking-opacity-80 {
  background-color: rgba(255, 99, 71, 0.8); /* Tomato with 80% opacity */
}

/* Stoneshaping section */
.stoneshaping {
  background-color: #A9A9A9; /* DarkGray color */
}

.stoneshaping-opacity-20 {
  background-color: rgba(169, 169, 169, 0.2); /* DarkGray with 20% opacity */
}

.stoneshaping-opacity-40 {
  background-color: rgba(169, 169, 169, 0.4); /* DarkGray with 40% opacity */
}

.stoneshaping-opacity-60 {
  background-color: rgba(169, 169, 169, 0.6); /* DarkGray with 60% opacity */
}

.stoneshaping-opacity-80 {
  background-color: rgba(169, 169, 169, 0.8); /* DarkGray with 80% opacity */
}

/* Metalworking section */
.metalworking {
  background-color: #B0C4DE; /* LightSteelBlue color */
}

.metalworking-opacity-20 {
  background-color: rgba(176, 196, 222, 0.2); /* LightSteelBlue with 20% opacity */
}

.metalworking-opacity-40 {
  background-color: rgba(176, 196, 222, 0.4); /* LightSteelBlue with 40% opacity */
}

.metalworking-opacity-60 {
  background-color: rgba(176, 196, 222, 0.6); /* LightSteelBlue with 60% opacity */
}

.metalworking-opacity-80 {
  background-color: rgba(176, 196, 222, 0.8); /* LightSteelBlue with 80% opacity */
}

.special {
  background-color: rgba(176, 196, 222, 1); /* LightSteelBlue with 80% opacity */
}
.special-opacity-20 {
  background-color: rgba(176, 196, 222, 0.2); /* LightSteelBlue with 80% opacity */
}
.special-opacity-30 {
  background-color: rgba(176, 196, 222, 0.3); /* LightSteelBlue with 80% opacity */
}
.special-opacity-50 {
  background-color: rgba(176, 196, 222, 0.5); /* LightSteelBlue with 80% opacity */
}
.special-opacity-70 {
  background-color: rgba(176, 196, 222, 0.7); /* LightSteelBlue with 80% opacity */
}
.special-opacity-80 {
  background-color: rgba(176, 196, 222, 0.8); /* LightSteelBlue with 80% opacity */
}
.special-opacity-90 {
  background-color: rgba(176, 196, 222, 0.9); /* LightSteelBlue with 80% opacity */
}